<template>
  <div>
    <b-list-group>
      <b-list-group-item
        v-for="(item, index) in content"
        :key="'datings-' + index"
      >
        {{ $t("pages.physicalObjects.dating") }}
        <b-row>
          <b-col>
            <b-form-input
              :id="'dating-input-' + index"
              v-model="item['dating']"
              @change="changed"
              @input="changed"
            />
          </b-col>
          <b-col sm="auto">
            <multiselect
              v-if="languages.length > 0"
              v-model="item.lang.id"
              :options="languages.map(i => i.id)"
              :custom-label="key => languages.find(x => x.id == key).sv"
              :show-labels="false"
              @select="changed"
            />
          </b-col>
        </b-row>
        <sources-selector
          v-if="item['sources']"
          :id="'datings-' + index + '-sources'"
          v-model="item['sources']"
          @change="changed"
        />
        <b-button
          v-t="'pages.physicalObjects.datingdelete'"
          @click="deleteDating(index)"
        />
      </b-list-group-item>
    </b-list-group>
    <b-button v-t="'pages.physicalObjects.datingnew'" @click="addDating" />
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "DatingsComponent",
  components: {
    SourcesSelector: () => import("@/components/SourcesSelector")
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      content: this.value,
      languages: []
    };
  },
  watch: {
    value() {
      this.content = this.value;
    }
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    },
    changed() {
      this.$emit("change");
    },
    addDating() {
      this.content.push({ dating: "", lang: { id: "sv-se" }, sources: [] });
      this.$emit("change");
    },
    deleteDating(index) {
      this.content.splice(index, 1);
      this.$emit("change");
    }
  },
  apollo: {
    languages() {
      return {
        query: gql`
          query languages {
            languages: modernLanguages {
              id: language
              sv
            }
          }
        `,
        result(result) {
          if (result.stale) {
            // result is called again with stale = true
            return;
          }

          this.languages = result.data.languages.sort((a, b) =>
            a.sv.localeCompare(b.sv, "sv")
          );
        }
      };
    }
  }
};
</script>
